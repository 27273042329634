// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { List as ImmutableList } from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import { darken, decomposeColor, recomposeColor } from '@material-ui/core/styles/colorManipulator';
import Drawer from '@material-ui/core/Drawer';
import Home from '@material-ui/icons/Home';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { TYPE_BAND_CHANNEL, PERMISSION_READ, CHANNEL_TYPES_IN_TREE } from 'shared-redux/src/graph/constants';
import { teamIdSelector, channelSelectorOpenSelector, activeLayoutSelector, deviceIdSelector, userIdSelector, isKioskSelector } from 'containers/App/selectors';
import { closeChannelSelector, openDialog, userLogin } from 'containers/App/actions';
import ChannelSelectorItem from 'components/ChannelSelectorItem';
import withSourcePermission from '@bunchtogether/boost-client/dist/components/withSourcePermission';
import withNode from '@bunchtogether/boost-client/dist/components/withNode';
import { clearScreenChannel } from '../../vendor/band-redux/src/app/actions';

const BAND_VERSION = process.env.BAND_VERSION || process.env.npm_package_version;

if (!BAND_VERSION) {
  throw new Error('Missing BAND_VERSION environment variable');
}

const styles = (theme: Object) => {
  const { values: primary } = decomposeColor(theme.palette.primary.main);
  const { values: secondary } = decomposeColor(theme.palette.secondary.main);
  const mixRgb = [(0.75 * primary[0] + 0.25 * secondary[0]) / 2, (0.75 * primary[1] + 0.25 * secondary[1]) / 2, (0.75 * primary[2] + 0.25 * secondary[2]) / 2];
  const mix = recomposeColor({ type: 'rgb', values: mixRgb });
  return {
    drawerRoot: {
      transform: 'translateZ(0)',
    },
    root: {
      zIndex: 11,
      position: 'relative',
      overflow: 'hidden',
      width: 310,
      backgroundColor: darken(theme.palette.primary.main, 0.2),
      background: `radial-gradient(circle, ${darken(theme.palette.primary.main, 0.4)} 10%, ${mix} 60%, ${darken(theme.palette.primary.main, 0.6)} 100%)`,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      minHeight: 'calc(100vh)',
      '&': {
        minHeight: 'calc(var(--vh, 1vh) * 100)',
      },
    },
    containerShadow: {
      position: 'absolute',
      top: -1 * theme.spacing(2),
      left: 0,
      bottom: -1 * theme.spacing(2),
      width: theme.spacing(2),
      boxShadow: `inset ${theme.spacing(1)}px 0px ${theme.spacing(1)}px 0px rgba(0, 0, 0, 0.4)`,
      zIndex: 13,
    },
    channels: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(8)}px ${theme.spacing(2)}px`,
      overflowX: 'hidden',
      overflowY: 'scroll',
      width: '100%',
    },
    buttonIcon: {
      marginRight: theme.spacing(1),
    },
    button: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
    },
    homeContainer: {
      color: '#FFF',
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    goHomeButton: {
      color: '#FFF',
      borderColor: '#FFF',
    },
    version: {
      fontFamily: theme.typography.fontFamily,
      textAlign: 'center',
      color: '#FFF',
    },
    fullWidth: {
      width: '100%',
    },
    qrCodePaper: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    qrCodePopper: {
      marginBottom: theme.spacing(2),
      marginRight: 310 + theme.spacing(2),
      zIndex: 1600,
    },
    qrCodeMessage: {
      marginTop: theme.spacing(1),
      fontSize: 13,
      fontWeight: '400',
    },
  };
};

type Props = {
  classes: ClassesType,
  targets: ImmutableList<string>,
  closeChannelSelector: Function,
  clearScreenChannel: Function,
  id: string,
  openDialog: Function,
  open: boolean,
  userId: string,
  deviceId: string,
  isKiosk: boolean,
  userLogin: Function
};

class ChannelSelector extends React.PureComponent<Props, void> { // eslint-disable-line react/prefer-stateless-function
  handleGoHome = () => {
    this.props.clearScreenChannel(this.props.id);
    this.props.closeChannelSelector();
  }

  handleLogout = () => {
    const { userId } = this.props;
    if (userId) {
      this.props.openDialog('userLogout');
    }
    this.props.closeChannelSelector();
  }

  handleSwitchTeam = () => {
    this.props.openDialog('switchTeam');
    this.props.closeChannelSelector();
  }

  handleUserLogin = () => {
    this.props.userLogin();
    this.props.closeChannelSelector();
  }

  renderLoginButton() {
    const { classes, userId, deviceId, isKiosk } = this.props;
    if (isKiosk) {
      return null;
    }
    if (!userId && deviceId) {
      return (
        <Button onClick={this.handleUserLogin}>
          <PowerSettingsNewIcon className={classes.buttonIcon} />
          Login
        </Button>
      );
    }
    return (
      <Button onClick={this.handleLogout} className={classes.button}>
        <PowerSettingsNewIcon className={classes.buttonIcon} />
        Logout
      </Button>
    );
  }

  render() {
    const { classes, targets, open } = this.props;
    let listData = [];
    if (targets && ImmutableList.isList(targets)) {
      listData = targets.toJS();
    }
    return (
      <React.Fragment>
        <Drawer open={open} anchor="right" onClose={this.props.closeChannelSelector}>
          <div className={classes.root}>
            <div className={classes.container}>
              <div className={classes.channels}>
                <div className={classes.homeContainer}>
                  <ButtonGroup size="small" color="secondary" variant="contained" fullWidth>
                    <Button onClick={this.handleGoHome} elevation={0}>
                      <Home className={classes.buttonIcon} />
                      Home
                    </Button>
                    {this.renderLoginButton()}
                    <Button onClick={this.handleSwitchTeam}>
                      <SwapCallsIcon className={classes.buttonIcon} />
                      Team
                    </Button>
                  </ButtonGroup>
                </div>
                {listData.map((id) => (
                  <ChannelSelectorItem id={id} key={id} />
                ))}
                <div className={classes.version}>
                  BAND v{BAND_VERSION}
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    );
  }
}

const withConnect = connect((state: StateType) => ({
  hasParent: teamIdSelector(state),
  open: channelSelectorOpenSelector(state),
  activeLayout: activeLayoutSelector(state),
  userId: userIdSelector(state),
  deviceId: deviceIdSelector(state),
  isKiosk: isKioskSelector(state),
}), (dispatch: Function) => bindActionCreators({ userLogin, closeChannelSelector, clearScreenChannel, openDialog }, dispatch));

export default compose(
  withStyles(styles, { withTheme: true }),
  withConnect,
  withNode(),
  withSourcePermission({
    type: TYPE_BAND_CHANNEL,
    permission: PERMISSION_READ,
    sort: 'name',
    typesInTree: CHANNEL_TYPES_IN_TREE,
  }),
)(ChannelSelector);

